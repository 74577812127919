import React, { useState, useEffect, useRef } from "react";
import "./intro.css";
import { MainWrapper } from "../../components/Layout";
import TitleImg from "./../../assets/images/metacore_title.png";
import AndroidImg from "./../../assets/images/Android.png";
import AppStoreImg from "./../../assets/images/App-store.png";
import TestflightImg from "./../../assets/images/Testflight.png";
import GooglePlayImg from "./../../assets/images/Google-play.png";
import GuideImg from "./../../assets/images/guide_icon.svg";
// import MetaCoreAPK from "%PUBLIC_URL%/apk/MetaCore.apk";

const IntroPage = () => {
    const [openModal, setOpenModal] = useState(false);
    return (
        <MainWrapper openModal={openModal} setOpenModal={setOpenModal}>
            <div className="intro-page">
                <div className="logo-wrap">
                    <img className="logo" src={TitleImg} alt="PlentyLand" />
                </div>
                {process.env.REACT_APP_ENV === "download" && (
                    <a className="core-btn btn-android" href={process.env.PUBLIC_URL + "/apk/PlentyLand.apk"}>
                        <img className="core-btn-img" src={AndroidImg} />
                        <div className="button-text-wrap">
                            <div>
                                <p className="caption-02-R">GET IT ON</p>
                                <p className="caption-01-S">Download APK</p>
                            </div>
                        </div>
                    </a>
                )}

                {/* <a className="core-btn btn-google" onClick={() => window.open("https://play.google.com/store/apps/details?id=com.metacore")}> */}
                <a className="core-btn btn-google" onClick={() => alert('coming soon')}>
                    <img className="core-btn-img" src={GooglePlayImg} />
                    <div className="button-text-wrap">
                        <div>
                            <p className="caption-02-R">GET IT ON</p>
                            <p className="caption-01-S">Google Play</p>
                        </div>
                    </div>
                </a>
                <a className="core-btn btn-apple" onClick={() => window.open("https://apps.apple.com/us/app/meta-core/id6453698019")}>
                    <img className="core-btn-img" src={AppStoreImg} />
                    <div className="button-text-wrap">
                        <div>
                            <p className="caption-02-R">Download on the</p>
                            <p className="caption-01-S">App Store</p>
                        </div>
                    </div>
                </a>
                {process.env.REACT_APP_ENV === "download" && (
                    <a className="core-btn btn-testFlight" onClick={() => window.open("https://testflight.apple.com/join/8LvBKWNQ")}>
                        <img className="core-btn-img" src={TestflightImg} />
                        <div className="button-text-wrap">
                            <div>
                                <p className="caption-02-R">Download on the</p>
                                <p className="caption-01-S">App Store Beta</p>
                            </div>
                        </div>
                    </a>
                )}

                <button className="guid-btn" onClick={() => setOpenModal(true)}>
                    <img src={GuideImg} alt={"guide"} />
                    <p className="caption-01-R">Game guide</p>
                </button>
            </div>
        </MainWrapper>
    );
};

export default IntroPage;
